import { useState } from 'react';
import { displayErrors, formatErrors } from '../../utils/auth';
import { toast } from 'react-toastify';

const useErrorHandler = () => {
  const [error, setError] = useState(null);
  const [errors, setErrors1] = useState(null);

  const handleReqError = (err, setErrors) => {
    if (err?.message) {
      toast.error(err?.message || 'An error occurred');
    }

    if (err?.errors) {
      setErrors1(formatErrors(err.errors));
      setTimeout(() => setErrors?.(displayErrors(err.errors)), 0);
    }
  };

  const handleReqErrors = (err, setErrors) => {
    if (err.errors) {
      setTimeout(() => setErrors?.(displayErrors(err.errors)), 0);
    }
  };

  const handleError = (err) => {
    setError(formatErrors(err));
  };

  const handleClose = () => {
    setError(null);
    setErrors1(null);
  };

  return {
    setError,
    handleReqError,
    handleReqErrors,
    handleError,
    error,
    errors,
    handleClose,
  };
};

export default useErrorHandler;
