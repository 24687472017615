import {
  REQUEST_ACTION,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAILURE,
  GET_SINGLE_INVOICE_SUCCESS,
  GET_SINGLE_INVOICE_FAILURE,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_FAILURE,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_FAILURE,
  REQUEST_CREATE_INVOICE_ACTION,
  REQUEST_ADD_PAYMENT_TO_AN_INVOICE,
  ADD_PAYMENT_TO_AN_INVOICE_SUCCESS,
  ADD_PAYMENT_TO_AN_INVOICE_FAILURE,
} from './actionTypes';
import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: REQUEST_ACTION,
  isLoading: true,
});

const requestCreateInvoiceAction = () => ({
  type: REQUEST_CREATE_INVOICE_ACTION,
  newInvoiceLoading: true,
});

export const getInvoices = (payload) => ({
  type: GET_INVOICES_SUCCESS,
  payload,
  isLoading: false,
});

export const getInvoicesFailure = () => ({
  type: GET_INVOICES_FAILURE,
  isLoading: false,
});

export const updateInvoiceSuccess = (payload) => ({
  type: UPDATE_INVOICE_SUCCESS,
  isLoading: false,
  payload,
});

export const createInvoiceSuccess = (payload) => ({
  type: CREATE_INVOICE_SUCCESS,
  newInvoiceLoading: false,
  payload,
});

export const createInvoiceFailure = (error) => ({
  type: CREATE_INVOICE_FAILURE,
  newInvoiceLoading: false,
  error,
});

export const updateInvoiceFailure = (error) => ({
  type: UPDATE_INVOICE_FAILURE,
  isLoading: false,
  error,
});

export const getSingleInvoice = (payload) => ({
  type: GET_SINGLE_INVOICE_SUCCESS,
  payload,
  isLoading: false,
});

export const getSingleInvoiceFailure = () => ({
  type: GET_SINGLE_INVOICE_FAILURE,
  isLoading: false,
});

const requestAddPaymentToAnInvoice = () => ({
  type: REQUEST_ADD_PAYMENT_TO_AN_INVOICE,
  addingPaymentToAnInvoice: true,
});

const addPaymentToAnInvoiceSuccess = (payload) => ({
  type: ADD_PAYMENT_TO_AN_INVOICE_SUCCESS,
  payload,
  addingPaymentToAnInvoice: false,
});

const addPaymentToAnInvoiceFailure = (error) => ({
  type: ADD_PAYMENT_TO_AN_INVOICE_FAILURE,
  error,
  addingPaymentToAnInvoice: false,
});

export const getInvoicesAction = (
  limit,
  page,
  userId,
  status,
  startDate,
  endDate,
) => async (dispatch) => {
  dispatch(requestAction());

  try {
    limit = limit ? `limit=${limit}` : '';
    page = page ? `&page=${page}` : '';
    status = status ? `&status=${status}` : '';
    userId = userId ? `&userId=${userId}` : '';
    startDate = startDate ? `&startDate=${startDate}` : '';
    endDate = endDate ? `&endDate=${endDate}` : '';
    const { data } = await axios.get(
      `/invoice/?${limit}${page}${status}${userId}${startDate}${endDate}`,
    );

    dispatch(getInvoices(data.data.invoices));

    return data;
  } catch (error) {
    dispatch(getInvoicesFailure());

    return errorHandler(error);
  }
};

export const createInvoiceAction = (payload) => async (dispatch) => {
  dispatch(requestCreateInvoiceAction());
  try {
    const { data } = await axios.post('/invoice', payload);
    dispatch(createInvoiceSuccess(data));

    return data;
  } catch (err) {
    dispatch(createInvoiceFailure(err));

    return errorHandler(err);
  }
};

export const getSingleInvoiceAction = (invoiceId) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get(`/invoice/${invoiceId}`);
    dispatch(getSingleInvoice(data.data));

    return data;
  } catch (error) {
    dispatch(getSingleInvoiceFailure());

    return errorHandler(error);
  }
};

export const updateInvoiceDetailsAction = (payload, invoiceId) => async (
  dispatch,
) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.patch(`/invoice/${invoiceId}`, payload);
    dispatch(updateInvoiceSuccess(data));

    return data;
  } catch (err) {
    dispatch(updateInvoiceFailure(err));

    return errorHandler(err);
  }
};

// add payment method

export const addPaymentToAnInvoiceAction = (invoiceNumber, payload) => async (
  dispatch,
) => {
  dispatch(requestAddPaymentToAnInvoice());
  try {
    const { data } = await axios.post(
      `/invoice/${invoiceNumber}/payments`,
      payload,
    );
    dispatch(addPaymentToAnInvoiceSuccess(data));

    return { success: true, data };
  } catch (err) {
    dispatch(addPaymentToAnInvoiceFailure(err));
    return errorHandler(err);
  }
};

export const initialState = {
  data: {},
  invoices: [],
  invoiceIsLoaded: false,
  invoiceLoadingFailure: false,
  accountHeaderStatus: true,
  isLoading: true,
  newInvoiceLoading: false,
  invoiceData: null,
  invoiceUpdateData: null,
  newInvoiceData: null,
  addingPaymentToAnInvoice: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case REQUEST_CREATE_INVOICE_ACTION:
      return {
        ...state,
        newInvoiceLoading: true,
      };
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        isLoading: false,
      };
    case GET_INVOICES_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_SINGLE_INVOICE_SUCCESS:
      return {
        ...state,
        invoiceData: action.payload,
        invoiceUpdateData: null,
        invoiceIsLoaded: true,
        invoiceLoadingFailure: false,
        isLoading: false,
      };
    case CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        newInvoiceLoading: false,
        newInvoiceData: action.payload.data,
      };
    case CREATE_INVOICE_FAILURE:
      return {
        ...state,
        newInvoiceLoading: false,
        error: action.error,
      };
    case UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        invoiceUpdateData: action.payload.data,
      };
    case UPDATE_INVOICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case ADD_PAYMENT_TO_AN_INVOICE_SUCCESS:
      return {
        ...state,
        addingPaymentToAnInvoice: false,
        paymentInvoiceData: action.payload.data,
      };
    case ADD_PAYMENT_TO_AN_INVOICE_FAILURE:
      return {
        ...state,
        addingPaymentToAnInvoice: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
