/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from '../Modal';
import Button from '../../components/Button';
import Select from '../SelectBox';
import { Formik, Form } from 'formik';
import DatesPicker from '../DatePicker';
import InputField from '../InputField';
import formatMoney from '../../utils/formatMoney';
import { formatDate } from '../../utils/timeFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import {
  validationSchemaForUpdatingInvoice,
  validationSchemaForAddingPayment,
  validationSchemaForRemittingPremium,
  validationSchemaForUploadingRemittanceDocument,
  validationSchemaForRemittingVAT,
  validationSchemaForUploadingVatDocument,
} from '../SingleInvoice/validation';
import useErrorHandler from '../hooks/useErrorHandler';
import { useDispatch } from 'react-redux';
import { generateCreditNoteAction } from '../../store/modules/quotes';
import ReactTooltip from 'react-tooltip';

const InvoiceDetailModal = (props) => {
  const {
    showModal,
    isInvoiceLoading,
    closeModal,
    setAddingPayment,
    invoiceModalAction,
    status,
    startDate,
    endDate,
    updatedData,
    narration,
    amountInsured,
    commissionRate,
    commissionType,
    updateInvoiceDetail,
    invoiceId,
    invoiceDetails,
    addPaymentToAnInvoiceAction,
    sendInvoiceToClient,
    handleMarkAsRemitted,
    getUnremittedData,
    remittanceId,
    uploadRemittanceDocument,
    getUnremittedVatData,
    handleVatRemitted,
    vatRemitId,
    uploadVatDocument,
  } = props;
  const history = useHistory();
  const [apiError, setApiError] = useState(null);
  const [showRedirectModal, setShowRedirectModal] = useState(false);

  const { handleReqError } = useErrorHandler();

  const renderModalHeader = (invoiceModalAction) => () => (
    <div>
      <h1 className="mb-6 text-2xl text-center uppercase font-black">
        {invoiceModalAction === 'add payment'
          ? 'Add Payment'
          : invoiceModalAction === 'remit premium'
          ? 'Remit Premium'
          : invoiceModalAction === 'upload'
          ? 'Upload Receipt'
          : invoiceModalAction === 'remit vat'
          ? 'Remit Vat'
          : invoiceModalAction === 'upload vat'
          ? 'Upload Vat Receipt'
          : 'update'}
      </h1>
    </div>
  );

  let formikSetFieldValue;
  let formState, setFormState;

  const [validationError, setValidationError] = useState({
    underwriterReceipt: '',
  });
  [formState, setFormState] = useState({
    underwriterReceipt: '',
  });

  const renderModalContent = (
    isInvoiceLoading,
    invoiceModalAction,
    status,
    amountInsured,
    narration,
    startDate,
    endDate,
    closeModal,
    updateInvoiceDetail,
    invoiceId,
    sendInvoiceToClient,
    invoiceDetails,
    addPaymentToAnInvoiceAction,
    commissionType,
    commissionRate,
  ) => () => {
    const handleClick = () => setApiError(null);

    /**
     * Sets file upload data
     *
     * @param {Object} event
     *
     * @returns {void}
     */
    const handleFileChange = (event) => {
      const { name, files } = event.target;

      if (!files[0]?.name) {
        setFormState({
          ...formState,
          underwriterReceiptFileName: '',
        });
        return;
      }

      const regexPattern1 = /[!@#$%^&*()+=[\]{};':"\\|,<>/?]+/;
      const regexPattern = /\W{2}/g;
      const fileName = files[0].name;
      const filesize = files[0].size;

      if (
        filesize > 2000000 ||
        fileName.length > 60 ||
        regexPattern1.test(fileName) ||
        fileName.match(regexPattern)
      ) {
        setValidationError({
          ...validationError,
          underwriterReceipt: `Check file! File size not more than 2MB, file name should not contain special character and not more than 60 characters`,
        });
        setTimeout(() => {
          setValidationError({
            underwriterReceipt: '',
          });
        }, 5000);

        return;
      }

      setFormState({
        ...formState,
        files,
        underwriterReceiptFileName: fileName,
      });
    };

    const handleSubmit = async (values, helpers) => {
      try {
        let response;
        if (invoiceModalAction === 'update') {
          const payload = {
            ...values,
            narration: values.narration,
            amount: values.amountInsured.replace('₦', '').replace(/,/g, ''),
            commissionRate: values.commissionRate
              .replace('₦', '')
              .replace(/,/g, ''),
            startDate: formatDate(values.startDate),
            endDate: formatDate(values.endDate),
          };
          response = await updateInvoiceDetail(payload, invoiceId);
        } else if (invoiceModalAction === 'add payment') {
          setAddingPayment(true);
          [response] = await Promise.all([
            addPaymentToAnInvoiceAction(
              invoiceDetails.invoiceDetails.invoice_no,
              {
                amount: values.amountToPay.replace('₦', '').replace(/,/g, ''),
                modeOfPayment: values.modeOfPayment,
                bankName: values.bankName,
                chequeNumber: values.chequeNumber,
                dateOfActualPayment: values.dateOfActualPayment,
              },
            ),
          ]);
        } else if (invoiceModalAction === 'remit premium') {
          const remittanceType = values.remittanceType.toUpperCase();
          const amountToRemit =
            values.remittanceType === 'Full Remittance'
              ? getUnremittedData.amountUnremitted
                  .replace('₦', '')
                  .replace(/,/g, '')
              : values.amountToPay;
          const dateToRemitBalance =
            values.remittanceType === 'Full Remittance'
              ? new Date().toISOString()
              : values.dateToRemitBalance;

          const payload = {
            remittanceType,
            date: values.date,
            amountToRemit,
            dateToRemitBalance,
            remark: values.remark,
          };

          if (
            values.remittanceMode === 'Bank Transfer' ||
            values.remittanceMode === 'Cheque' ||
            values.remittanceMode === 'Other'
          ) {
            payload.remittanceMode = values.remittanceMode;

            if (values.remittanceMode === 'Bank Transfer') {
              payload.remittanceBankName = values.remittanceBankName;
            } else if (values.remittanceMode === 'Cheque') {
              payload.remittanceBankName = values.remittanceBankName;
              payload.remittanceChequeNumber = values.remittanceChequeNumber;
            } else if (values.remittanceMode === 'Other') {
              payload.otherRemitDescription = values.otherRemitDescription;
            }
          }

          response = await handleMarkAsRemitted(payload);
        } else if (invoiceModalAction === 'upload') {
          const payload = {
            remittanceId: remittanceId,
            underwriterReceiptNumber: values.underwriterReceiptNumber,
            files: formState.files?.length ? formState.files[0] : [],
          };

          const formData = new FormData();

          Object.entries(payload).forEach(([key, value]) => {
            formData.append(key, value);
          });

          response = await uploadRemittanceDocument(formData);
          setFormState({
            underwriterReceipt: '',
          });
        } else if (invoiceModalAction === 'remit vat') {
          const remittanceType = values.remittanceType.toUpperCase();
          const vatAmountToRemit =
            values.remittanceType === 'Full Remittance'
              ? getUnremittedVatData.amountUnremitted
                  .replace('₦', '')
                  .replace(/,/g, '')
              : values.amountToPay;

          const payload = {
            remittanceType,
            date: values.date,
            vatAmountToRemit,
            // dateToRemitBalance,
            // remark: values.remark,
          };

          if (
            values.remittanceMode === 'Bank Transfer' ||
            values.remittanceMode === 'Cheque'
          ) {
            payload.remittanceMode = values.remittanceMode;

            if (values.remittanceMode === 'Bank Transfer') {
              payload.remittanceBankName = values.remittanceBankName;
            } else if (values.remittanceMode === 'Cheque') {
              payload.remittanceBankName = values.remittanceBankName;
              payload.remittanceChequeNumber = values.remittanceChequeNumber;
            }
          }

          response = await handleVatRemitted(payload);
        } else if (invoiceModalAction === 'upload vat') {
          const payload = {
            vatRemitId: vatRemitId,
            vatReceiptNumber: values.vatReceiptNumber,
            files: formState.files?.length ? formState.files[0] : [],
          };

          const formData = new FormData();

          Object.entries(payload).forEach(([key, value]) => {
            formData.append(key, value);
          });

          response = await uploadVatDocument(formData);
          setFormState({
            underwriterReceipt: '',
          });
        } else {
          response = await sendInvoiceToClient();
        }

        if (response?.error) {
          if (!response?.error?.response?.success) {
            toast.error(
              response?.error?.response?.data?.message || response.error,
            );
            setAddingPayment(false);
            return;
          }
        }

        if (!response?.success) {
          handleReqError(response);
        } else {
          toast.success('Action completed successfully');
          history.push(
            `/account/invoice/${
              invoiceDetails?.invoiceDetails?.invoice_id ?? invoiceId
            }`,
          );
          closeModal();

          if (invoiceModalAction === 'add payment') {
            setShowRedirectModal(true);
          }
        }

        if (helpers) {
          helpers.resetForm();
        }

        closeModal();

        if (invoiceModalAction === 'add payment') {
          setAddingPayment(false);
        }
      } catch (e) {
        toast.error(e.message || 'An error occured');
      }
    };

    return (
      <div>
        {apiError ? (
          <div className="relative max-w-sm mx-auto mb-4 text-xs text-center bg-red-200 border border-red-300 border-solid rounded-md text-grey-800 ">
            <button
              onClick={handleClick}
              className="absolute top-0 right-0 m-4 focus:border focus:border-red-400 focus:border-solid"
            >
              <svg
                width="10"
                height="10"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.477297"
                  y1="0.522703"
                  x2="19.4985"
                  y2="19.5439"
                  stroke="red"
                  strokeWidth="3"
                />
                <line
                  x1="19.4985"
                  y1="0.477297"
                  x2="0.477303"
                  y2="19.4985"
                  stroke="red"
                  strokeWidth="3"
                />
              </svg>
            </button>
            {apiError}
          </div>
        ) : null}
        {invoiceModalAction === 'update' ? (
          <Formik
            initialValues={{
              amountInsured,
              startDate: new Date(startDate),
              endDate: new Date(endDate),
              status,
              narration,
              commissionRate,
              commissionType,
            }}
            validationSchema={validationSchemaForUpdatingInvoice()}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
              isValid,
              dirty,
              handleSubmit,
              resetForm,
              setErrors,
              errors,
            }) => {
              const amountInsured = isNaN(
                Number(values.amountInsured.replace('₦', '').replace(/,/g, '')),
              )
                ? values.amountInsured
                : formatMoney(
                    values.amountInsured.replace('₦', '	').replace(/,/g, ''),
                    0,
                  );

              const handleInputChange = (event) => {
                const { name, value } = event.target;

                setFieldValue(name, value);
              };

              return (
                <Form>
                  <section className="w-full mt-6 shadow-pc">
                    <div className="flex items-center justify-between h-10 bg-brown-100">
                      <h1 className="pl-6">Update Invoice</h1>
                      <FontAwesomeIcon
                        icon={faTimes}
                        color="#000"
                        className="mr-5 cursor-pointer"
                        size={'xs'}
                        onClick={() => {
                          resetForm();
                          closeModal();
                        }}
                      />
                    </div>
                    <div className="px-6 py-10 bg-white">
                      <div className="mb-4 lg:flex lg:justify-between">
                        <InputField
                          inputClassName="h-12"
                          className="mb-4 lg:w-8/25 md:full sm:mb-4 md:mb-4"
                          placeholder="Amount Insured"
                          money
                          name="amountInsured"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          edited={touched}
                          value={amountInsured}
                          errorMessage={errors.amountInsured}
                        />

                        <DatesPicker
                          className="mb-4 lg:w-8/25 md:full sm:mb-4 md:mb-4"
                          name="startDate"
                          selected={values.startDate}
                          showYearDropdown
                          dropdownMode="select"
                          onChange={(date) => {
                            setFieldValue('startDate', date);
                          }}
                          placeholder="Start Date"
                          required
                          dateFormat="yyyy-MM-dd"
                          setErrors={setErrors}
                          value={values.startDate}
                          errorMessage={errors.startDate}
                        />
                        <DatesPicker
                          className="mb-4 lg:w-8/25 md:full sm:mb-4 md:mb-4"
                          name="endDate"
                          selected={values.endDate}
                          showYearDropdown
                          dropdownMode="select"
                          onChange={(date) => setFieldValue('endDate', date)}
                          placeholder="End Date"
                          required
                          dateFormat="yyyy-MM-dd"
                          setErrors={setErrors}
                          value={values.endDate}
                          errorMessage={errors.endDate}
                        />
                      </div>
                      <div className="mb-4 lg:flex lg:justify-between">
                        <Select
                          name="commissionType"
                          description="Select Commission Type"
                          className="mb-4 lg:w-12/25 md:full sm:mb-4 md:mb-4"
                          style={{
                            backgroundPosition: '97% 50%',
                          }}
                          options={['Flat', 'Percentage']}
                          value={values.commissionType}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          edited={touched}
                          errorMessage={errors.commissionType}
                        />
                        <InputField
                          inputClassName="h-12"
                          className="mb-4 lg:w-12/25 md:full sm:mb-4 md:mb-4"
                          placeholder="Commission Rate"
                          money={values.commissionType === 'Flat'}
                          name="commissionRate"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          edited={touched}
                          value={values.commissionRate}
                          errorMessage={errors.commissionRate}
                        />
                      </div>

                      <div className="mb-4">
                        <InputField
                          textarea
                          placeholder="Invoice Narration"
                          name="narration"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          edited={touched}
                          value={values.narration}
                          errorMessage={errors.narration}
                        />
                      </div>
                    </div>

                    <div className="px-6 py-1 bg-white">
                      {invoiceModalAction === 'send' && (
                        <div>Send invoice to client</div>
                      )}
                      <div className="flex justify-end mb-6">
                        <Button
                          type="button"
                          onClick={() => {
                            resetForm();
                            closeModal();
                          }}
                          name="Cancel"
                          className="mr-1 text-white bg-red-500 border border-white hover:bg-red-400 focus:border-solid focus:border-gray-300"
                        />
                        <Button
                          type="submit"
                          disabled={isInvoiceLoading || !isValid || !dirty}
                          onClick={(values) => handleSubmit(values, resetForm)}
                          name={
                            invoiceModalAction === 'update'
                              ? 'Update'
                              : 'Add Payment'
                          }
                          className={
                            invoiceModalAction === 'update' &&
                            (!isValid || !dirty)
                              ? 'bg-blue-200 text-white opacity-75 cursor-not-allowed w-20'
                              : isInvoiceLoading
                              ? 'bg-white border border-blue-350 cursor-not-allowed w-20'
                              : 'bg-blue-750 hover:bg-blue-700 text-white w-20'
                          }
                          isLoading={isInvoiceLoading}
                        />
                      </div>
                    </div>
                  </section>
                </Form>
              );
            }}
          </Formik>
        ) : invoiceModalAction === 'add payment' ? (
          <Formik
            initialValues={{
              amountToPay: '0',
              balance: '',
              modeOfPayment: 'Cash',
              bankName: '',
              chequeNumber: 0,
              dateOfActualPayment: new Date(),
            }}
            validationSchema={validationSchemaForAddingPayment()}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
              isValid,
              handleSubmit,
              errors,
              setErrors,
            }) => {
              formikSetFieldValue = setFieldValue;

              const handleInputChange = (event) => {
                const { name, value } = event.target;

                setFieldValue(name, value);
              };

              const amountToPay = values.amountToPay;

              return (
                <Form>
                  <section className="w-full mt-6 shadow-pc">
                    <div className="flex items-center h-10 bg-brown-100">
                      <h1 className="pl-6">Add payment</h1>
                    </div>
                    <div className="px-6 pt-10 pb-4 bg-white">
                      <div className="mb-4 lg:w-full">
                        <DatesPicker
                          className="mb-4 md:full sm:mb-4 md:mb-4"
                          name="dateOfActualPayment"
                          selected={values.dateOfActualPayment}
                          showYearDropdown
                          dropdownMode="select"
                          onChange={(date) => {
                            setFieldValue('dateOfActualPayment', date);
                          }}
                          placeholder="Date Of Actual Payment"
                          required
                          dateFormat="yyyy-MM-dd"
                          setErrors={setErrors}
                          value={values.dateOfActualPayment}
                          errorMessage={errors.dateOfActualPayment}
                        />
                      </div>
                      <div className="mb-4 lg:w-full">
                        <Select
                          name="modeOfPayment"
                          description="Select Mode of Payment"
                          className="mb-4 lg:w md:full sm:mb-4 md:mb-4"
                          style={{
                            backgroundPosition: '97% 50%',
                          }}
                          options={[
                            'Cash',
                            'Bank Transfer',
                            'Cheque',
                            'Direct to Insurer',
                          ]}
                          value={values.modeOfPayment}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          edited={touched}
                          errorMessage={errors.modeOfPayment}
                        />
                      </div>
                      {values.modeOfPayment ===
                      'Cash' ? null : values.modeOfPayment ===
                        'Bank Transfer' ? (
                        <div className="mb-2">
                          <InputField
                            value={values.bankName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            edited={touched}
                            errorMessage={errors.bankName}
                            className="mb-4 lg:w-full md:w-full sm:mb-4 md:mb-4"
                            placeholder="Bank Name"
                            name="bankName"
                          />
                        </div>
                      ) : values.modeOfPayment === 'Cheque' ? (
                        <div className="mb-2">
                          <InputField
                            value={values.bankName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            edited={touched}
                            errorMessage={errors.bankName}
                            className="mb-4 lg:w-full md:w-full sm:mb-4 md:mb-4"
                            placeholder="Bank Name"
                            name="bankName"
                          />
                          <InputField
                            value={values.chequeNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            edited={touched}
                            errorMessage={errors.chequeNumber}
                            className="mb-4 lg:w-full md:w-full sm:mb-4 md:mb-4"
                            placeholder="Cheque Number"
                            name="chequeNumber"
                          />
                        </div>
                      ) : values.modeOfPayment ===
                        'Direct to Insurer' ? null : null}
                      <div className="mb-4 lg:flex lg:justify-between">
                        <InputField
                          inputClassName="h-12"
                          className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                          placeholder="Amount To Pay"
                          name="amountToPay"
                          onChange={handleChange}
                          money
                          onBlur={handleBlur}
                          edited={touched}
                          value={amountToPay}
                          errorMessage={errors.amountToPay}
                        />
                      </div>
                    </div>

                    <div className="px-6 bg-white">
                      <div className="mb-4 lg:flex lg:justify-between">
                        <InputField
                          inputClassName="h-12"
                          className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                          placeholder="Outstanding Balance"
                          name="balance"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          edited={touched}
                          isDisabled={true}
                          value={values.balance}
                          errorMessage={errors.balance}
                        />
                      </div>
                    </div>
                    <div className="px-6 py-1 bg-white">
                      {invoiceModalAction === 'send' && (
                        <div>Send invoice to client</div>
                      )}
                      <div className="flex justify-end mb-6">
                        <Button
                          type="button"
                          onClick={() => {
                            if (invoiceModalAction === 'add payment') {
                              setFieldValue('amountToPay', '0');
                              setFieldValue('modeOfPayment', 'Cash');
                              setFieldValue('bankName', '');
                              setFieldValue('chequeNumber', 0);
                              setFieldValue('dateOfActualPayment', new Date());
                            }
                            closeModal();
                          }}
                          name="Cancel"
                          className="p-1 mr-1 text-white bg-red-500 border border-white hover:bg-red-400 focus:border-solid focus:border-gray-300"
                        />
                        <Button
                          type="submit"
                          disabled={isInvoiceLoading || !isValid}
                          onClick={handleSubmit}
                          name="Add Payment"
                          className={
                            !isValid
                              ? 'text-gray-600 bg-gray-200 cursor-not-allowed w-40 font-bold'
                              : isInvoiceLoading
                              ? 'text-gray-600 bg-gray-200 cursor-not-allowed w-40 font-bold'
                              : 'bg-blue-850 hover:bg-blue-730 text-white w-40 font-bold'
                          }
                          isLoading={isInvoiceLoading}
                        />
                      </div>
                    </div>
                  </section>
                </Form>
              );
            }}
          </Formik>
        ) : invoiceModalAction === 'remit premium' ? (
          <Formik
            initialValues={{
              remittanceType: 'Full Remittance',
              amountToPay: '0',
              date: null,
              dateToRemitBalance: null,
              remittanceMode: '',
              remittanceBankName: '',
              remittanceChequeNumber: '',
              otherRemitDescription: '',
              remark: '',
            }}
            validationSchema={validationSchemaForRemittingPremium()}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
              isValid,
              handleSubmit,
              errors,
              setErrors,
            }) => {
              formikSetFieldValue = setFieldValue;

              const handleInputChange = (event) => {
                const { name, value } = event.target;

                setFieldValue(name, value);
              };
              return (
                <Form
                  onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit(values);
                  }}
                >
                  <section
                    className="w-full mt-6 shadow-pc"
                    style={{
                      width: '30vw',
                    }}
                  >
                    <div className="flex items-center h-10 bg-brown-100">
                      <h1 className="pl-6">Remit Premium</h1>
                    </div>
                    <div className="grid items-center w-full gap-4 grid-cols-2">
                      <div className="pl-6 pt-4 bg-white">
                        <div className="mb-4 lg:flex lg:justify-between">
                          <Select
                            name="remittanceType"
                            description="Remittance Type"
                            className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                            style={{
                              backgroundPosition: '97% 50%',
                            }}
                            options={['Full Remittance', 'Partial Remittance']}
                            value={values.remittanceType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            edited={touched}
                            errorMessage={errors.remittanceType}
                          />
                        </div>
                      </div>
                      <div className="py-4 pr-6 bg-white">
                        <Select
                          name="remittanceMode"
                          description="Remittance Mode"
                          className="mb-4 lg:w md:full sm:mb-4 md:mb-4"
                          style={{
                            backgroundPosition: '97% 50%',
                          }}
                          options={['Bank Transfer', 'Cheque', 'Other']}
                          value={values.remittanceMode}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          edited={touched}
                          errorMessage={errors.remittanceMode}
                        />
                      </div>
                    </div>
                    {values.remittanceMode === 'Cheque' && (
                      <div className="px-6 bg-white grid items-center w-full gap-4 grid-cols-2">
                        <InputField
                          className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                          placeholder="Bank Name"
                          name="remittanceBankName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          edited={touched}
                          value={values.remittanceBankName}
                          errorMessage={errors.remittanceBankName}
                        />
                        <InputField
                          className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                          placeholder="Cheque Number"
                          name="remittanceChequeNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          edited={touched}
                          value={values.remittanceChequeNumber}
                          errorMessage={errors.remittanceChequeNumber}
                        />
                      </div>
                    )}
                    {values.remittanceMode === 'Bank Transfer' && (
                      <div className="px-6 bg-white">
                        <InputField
                          className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                          placeholder="Bank Name"
                          name="remittanceBankName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          edited={touched}
                          value={values.remittanceBankName}
                          errorMessage={errors.remittanceBankName}
                        />
                      </div>
                    )}
                    {values.remittanceMode === 'Other' && (
                      <div className="px-6 bg-white">
                        <InputField
                          className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                          placeholder="Other Remittance Description"
                          name="otherRemitDescription"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          edited={touched}
                          value={values.otherRemitDescription}
                          errorMessage={errors.otherRemitDescription}
                        />
                      </div>
                    )}
                    {values.remittanceType === 'Partial Remittance' && (
                      <div className="px-6 bg-white">
                        <div className="mb-4 lg:flex lg:justify-between">
                          <InputField
                            inputClassName="h-12"
                            className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                            placeholder="Amount"
                            name="amountToPay"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            edited={touched}
                            value={values.amountToPay}
                            errorMessage={errors.amountToPay}
                            money
                          />
                        </div>
                      </div>
                    )}
                    <div className="px-6 bg-white">
                      <div className="grid items-center w-full gap-4 grid-cols-2">
                        <div className="mb-4 lg:flex lg:justify-between">
                          <DatesPicker
                            className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                            name="date"
                            selected={values.date}
                            showYearDropdown
                            dropdownMode="select"
                            onChange={(date) => {
                              setFieldValue('date', date);
                            }}
                            placeholder="Remittance Date"
                            required
                            dateFormat="yyyy-MM-dd"
                            setErrors={setErrors}
                            value={values.date}
                            errorMessage={errors.date}
                          />
                        </div>

                        {values.remittanceType === 'Partial Remittance' && (
                          <div className="mb-4 lg:flex lg:justify-between relative">
                            <ReactTooltip
                              id="date-to-remit-balance"
                              place="top"
                              type="dark"
                              effect="float"
                            />
                            <DatesPicker
                              className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                              name="dateToRemitBalance"
                              selected={values.dateToRemitBalance}
                              showYearDropdown
                              dropdownMode="select"
                              onChange={(date) => {
                                setFieldValue('dateToRemitBalance', date);
                              }}
                              placeholder="Date to Remit Balance"
                              required
                              dateFormat="yyyy-MM-dd"
                              setErrors={setErrors}
                              value={values.dateToRemitBalance}
                              errorMessage={errors.dateToRemitBalance}
                            />
                            <FontAwesomeIcon
                              icon={faQuestionCircle}
                              data-tip="Premium should be remitted within 30 days after which it has been paid"
                              data-for="date-to-remit-balance"
                              className="absolute z-50 cursor-pointer top-1 right-2"
                              color="grey"
                            />
                          </div>
                        )}
                      </div>

                      <div className="grid items-center w-full gap-4 grid-cols-2">
                        <div className="mb-4 lg:flex lg:justify-between">
                          <InputField
                            inputClassName="h-12"
                            className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                            placeholder="Remittance Remark (if any)"
                            name="remark"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            edited={touched}
                            value={values.remark}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="px-6 bg-white">
                      <div className="mb-4 lg:flex lg:justify-between">
                        <InputField
                          inputClassName="h-12"
                          className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                          placeholder="Amount Unremitted"
                          value={getUnremittedData.amountUnremitted}
                          isDisabled
                        />
                      </div>
                    </div>
                    <div className="px-6 py-1 bg-white">
                      <div className="flex justify-end mb-6">
                        <Button
                          type="button"
                          onClick={() => {
                            if (invoiceModalAction === 'remit premium') {
                              setFieldValue('amountToPay', '0');
                              setFieldValue(
                                'remittanceType',
                                'Full Remittance',
                              );
                              setFieldValue('remittanceMode', '');
                              setFieldValue('remittanceBankName', '');
                              setFieldValue('remittanceChequeNumber', '');
                              setFieldValue('remark', '');
                            }
                            closeModal();
                          }}
                          name="Cancel"
                          className="p-1 mr-1 text-white bg-red-500 border border-white hover:bg-red-400 focus:border-solid focus:border-gray-300"
                        />

                        <Button
                          type="submit"
                          disabled={isInvoiceLoading || !isValid}
                          name="Remit Payment"
                          className={
                            !isValid
                              ? 'text-gray-600 bg-gray-200 cursor-not-allowed w-40 font-bold'
                              : isInvoiceLoading
                              ? 'text-gray-600 bg-gray-200 cursor-not-allowed w-40 font-bold'
                              : 'bg-blue-850 hover:bg-blue-730 text-white w-40 font-bold'
                          }
                          isLoading={isInvoiceLoading}
                        />
                      </div>
                    </div>
                  </section>
                </Form>
              );
            }}
          </Formik>
        ) : invoiceModalAction === 'remit vat' ? (
          <Formik
            initialValues={{
              remittanceType: 'Full Remittance',
              amountToPay: 0,
              date: null,
              remittanceMode: '',
              remittanceBankName: '',
              remittanceChequeNumber: '',
            }}
            validationSchema={validationSchemaForRemittingVAT()}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
              isValid,
              handleSubmit,
              errors,
              setErrors,
            }) => {
              formikSetFieldValue = setFieldValue;

              const handleInputChange = (event) => {
                const { name, value } = event.target;

                setFieldValue(name, value);
              };
              return (
                <Form
                  onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit(values);
                  }}
                >
                  <section
                    className="w-full mt-6 shadow-pc"
                    style={{
                      width: '30vw',
                    }}
                  >
                    <div className="flex items-center h-10 bg-brown-100">
                      <h1 className="pl-6">Remit Vat</h1>
                    </div>
                    <div className="grid items-center w-full gap-4 grid-cols-2">
                      <div className="pl-6 pt-4 bg-white">
                        <div className="mb-4 lg:flex lg:justify-between">
                          <Select
                            name="remittanceType"
                            description="Remittance Type"
                            className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                            style={{
                              backgroundPosition: '97% 50%',
                            }}
                            options={['Full Remittance', 'Partial Remittance']}
                            value={values.remittanceType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            edited={touched}
                            errorMessage={errors.remittanceType}
                          />
                        </div>
                      </div>
                      <div className="py-4 pr-6 bg-white">
                        <Select
                          name="remittanceMode"
                          description="Remittance Mode"
                          className="mb-4 lg:w md:full sm:mb-4 md:mb-4"
                          style={{
                            backgroundPosition: '97% 50%',
                          }}
                          options={['Bank Transfer', 'Cheque']}
                          value={values.remittanceMode}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          edited={touched}
                          errorMessage={errors.remittanceMode}
                        />
                      </div>
                    </div>
                    {values.remittanceMode === 'Cheque' && (
                      <div className="px-6 bg-white grid items-center w-full gap-4 grid-cols-2">
                        <InputField
                          className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                          placeholder="Bank Name"
                          name="remittanceBankName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          edited={touched}
                          value={values.remittanceBankName}
                          errorMessage={errors.remittanceBankName}
                        />
                        <InputField
                          className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                          placeholder="Cheque Number"
                          name="remittanceChequeNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          edited={touched}
                          value={values.remittanceChequeNumber}
                          errorMessage={errors.remittanceChequeNumber}
                        />
                      </div>
                    )}
                    {values.remittanceMode === 'Bank Transfer' && (
                      <div className="px-6 bg-white">
                        <InputField
                          className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                          placeholder="Bank Name"
                          name="remittanceBankName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          edited={touched}
                          value={values.remittanceBankName}
                          errorMessage={errors.remittanceBankName}
                        />
                      </div>
                    )}

                    {values.remittanceType === 'Partial Remittance' && (
                      <div className="px-6 bg-white">
                        <div className="mb-4 lg:flex lg:justify-between">
                          <InputField
                            inputClassName="h-12"
                            className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                            placeholder="Amount"
                            name="amountToPay"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            edited={touched}
                            value={values.amountToPay}
                            errorMessage={errors.amountToPay}
                            money
                          />
                        </div>
                      </div>
                    )}
                    <div className="px-6 bg-white">
                      {/* <div className="grid items-center w-full gap-4 grid-cols-2"> */}
                      <div className="mb-4 lg:flex lg:justify-between">
                        <DatesPicker
                          className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                          name="date"
                          selected={values.date}
                          showYearDropdown
                          dropdownMode="select"
                          onChange={(date) => {
                            setFieldValue('date', date);
                          }}
                          placeholder="Remittance Date"
                          required
                          dateFormat="yyyy-MM-dd"
                          setErrors={setErrors}
                          value={values.date}
                          errorMessage={errors.date}
                        />
                      </div>
                      {/* </div> */}
                    </div>
                    <div className="px-6 bg-white">
                      <div className="mb-4 lg:flex lg:justify-between">
                        <InputField
                          inputClassName="h-12"
                          className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                          placeholder="Amount Unremitted"
                          value={getUnremittedVatData.amountUnremitted}
                          isDisabled
                        />
                      </div>
                    </div>
                    <div className="px-6 py-1 bg-white">
                      <div className="flex justify-end mb-6">
                        <Button
                          type="button"
                          onClick={() => {
                            if (invoiceModalAction === 'remit vat') {
                              setFieldValue(
                                'remittanceType',
                                'Full Remittance',
                              );
                              setFieldValue('remittanceMode', '');
                              setFieldValue('remittanceBankName', '');
                              setFieldValue('remittanceChequeNumber', '');
                            }
                            closeModal();
                          }}
                          name="Cancel"
                          className="p-1 mr-1 text-white bg-red-500 border border-white hover:bg-red-400 focus:border-solid focus:border-gray-300"
                        />

                        <Button
                          type="submit"
                          disabled={isInvoiceLoading || !isValid}
                          name="Remit VAT"
                          className={
                            !isValid
                              ? 'text-gray-600 bg-gray-200 cursor-not-allowed w-40 font-bold'
                              : isInvoiceLoading
                              ? 'text-gray-600 bg-gray-200 cursor-not-allowed w-40 font-bold'
                              : 'bg-blue-850 hover:bg-blue-730 text-white w-40 font-bold'
                          }
                          isLoading={isInvoiceLoading}
                        />
                      </div>
                    </div>
                  </section>
                </Form>
              );
            }}
          </Formik>
        ) : invoiceModalAction === 'upload' ? (
          <Formik
            initialValues={{
              underwriterReceiptNumber: '',
              underwriterReceipt: '',
            }}
            validationSchema={validationSchemaForUploadingRemittanceDocument()}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
              isValid,
              handleSubmit,
              errors,
              setErrors,
            }) => {
              formikSetFieldValue = setFieldValue;

              return (
                <Form
                  onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit(values);
                  }}
                >
                  <section
                    className="w-full mt-6 shadow-pc"
                    style={{
                      width: '30vw',
                    }}
                  >
                    <div className="flex items-center h-10 bg-brown-100">
                      <h1 className="pl-6">Upload Receipt Document</h1>
                    </div>

                    <div className="px-6 bg-white">
                      <div className="grid items-center w-full gap-4 grid-cols-2 mt-4">
                        <div className="mb-4 lg:flex lg:justify-between">
                          <InputField
                            inputClassName="h-12"
                            className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                            placeholder="Underwriter Receipt No"
                            name="underwriterReceiptNumber"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            edited={touched}
                            value={values.underwriterReceiptNumber}
                            errorMessage={errors.underwriterReceiptNumber}
                          />
                        </div>

                        <div className="flex mb-8 lg:flex lg:justify-between">
                          <label className="w-max p-2 text-base text-white rounded-sm cursor-pointer bg-blue-750 hover:bg-blue-700 focus:outline-none">
                            <input
                              type="file"
                              className="hidden"
                              accept=".pdf, .jpeg, .jpg, .png"
                              name="underwriterReceipt"
                              onChange={handleFileChange}
                            />
                            Upload Receipt
                          </label>
                          <p className="mt-2 ml-2 font-bold">
                            {/* {`${formState.underwriterReceipt.length} ${
                                formState.underwriterReceipt.length > 1
                                  ? 'files'
                                  : 'file'
                              } selected`} */}
                            {formState.underwriterReceiptFileName
                              ? `1 file selected`
                              : ''}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="px-6 py-1 bg-white">
                      <div className="flex justify-end mb-6">
                        <Button
                          type="button"
                          onClick={() => {
                            if (invoiceModalAction === 'upload') {
                              setFieldValue('underwriterReceiptNumber', '');
                            }
                            closeModal();
                          }}
                          name="Cancel"
                          className="p-1 mr-1 text-white bg-red-500 border border-white hover:bg-red-400 focus:border-solid focus:border-gray-300"
                        />

                        <Button
                          type="submit"
                          disabled={isInvoiceLoading || !isValid}
                          name="Upload Receipt"
                          className={
                            !isValid
                              ? 'text-gray-600 bg-gray-200 cursor-not-allowed w-40 font-bold'
                              : isInvoiceLoading
                              ? 'text-gray-600 bg-gray-200 cursor-not-allowed w-40 font-bold'
                              : 'bg-blue-850 hover:bg-blue-730 text-white w-40 font-bold'
                          }
                          isLoading={isInvoiceLoading}
                        />
                      </div>
                    </div>
                  </section>
                </Form>
              );
            }}
          </Formik>
        ) : invoiceModalAction === 'upload vat' ? (
          <Formik
            initialValues={{
              vatReceiptNumber: '',
              underwriterReceipt: '',
            }}
            validationSchema={validationSchemaForUploadingVatDocument()}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
              isValid,
              handleSubmit,
              errors,
              setErrors,
            }) => {
              formikSetFieldValue = setFieldValue;

              return (
                <Form
                  onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit(values);
                  }}
                >
                  <section
                    className="w-full mt-6 shadow-pc"
                    style={{
                      width: '30vw',
                    }}
                  >
                    <div className="flex items-center h-10 bg-brown-100">
                      <h1 className="pl-6">Upload VAT Receipt</h1>
                    </div>

                    <div className="px-6 bg-white">
                      <div className="grid items-center w-full gap-4 grid-cols-2 mt-4">
                        <div className="mb-4 lg:flex lg:justify-between">
                          <InputField
                            inputClassName="h-12"
                            className="mb-4 lg:w-full md:full sm:mb-4 md:mb-4"
                            placeholder="Vat Receipt No"
                            name="vatReceiptNumber"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            edited={touched}
                            value={values.vatReceiptNumber}
                            errorMessage={errors.vatReceiptNumber}
                          />
                        </div>

                        <div className="flex mb-8 lg:flex lg:justify-between">
                          <label className="w-max p-2 text-base text-white rounded-sm cursor-pointer bg-blue-750 hover:bg-blue-700 focus:outline-none">
                            <input
                              type="file"
                              className="hidden"
                              accept=".pdf, .jpeg, .jpg, .png"
                              name="underwriterReceipt"
                              onChange={handleFileChange}
                            />
                            Upload Receipt
                          </label>
                          <p className="mt-2 ml-2 font-bold">
                            {formState.underwriterReceiptFileName
                              ? `1 file selected`
                              : ''}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="px-6 py-1 bg-white">
                      <div className="flex justify-end mb-6">
                        <Button
                          type="button"
                          onClick={() => {
                            if (invoiceModalAction === 'upload vat') {
                              setFieldValue('vatReceiptNumber', '');
                            }
                            closeModal();
                          }}
                          name="Cancel"
                          className="p-1 mr-1 text-white bg-red-500 border border-white hover:bg-red-400 focus:border-solid focus:border-gray-300"
                        />

                        <Button
                          type="submit"
                          disabled={isInvoiceLoading || !isValid}
                          name="Upload Receipt"
                          className={
                            !isValid
                              ? 'text-gray-600 bg-gray-200 cursor-not-allowed w-40 font-bold'
                              : isInvoiceLoading
                              ? 'text-gray-600 bg-gray-200 cursor-not-allowed w-40 font-bold'
                              : 'bg-blue-850 hover:bg-blue-730 text-white w-40 font-bold'
                          }
                          isLoading={isInvoiceLoading}
                        />
                      </div>
                    </div>
                  </section>
                </Form>
              );
            }}
          </Formik>
        ) : null}
      </div>
    );
  };

  useEffect(() => {
    if (invoiceDetails) {
      let balance = String(
        Number(invoiceDetails.invoiceDetails.amount) -
          Number(invoiceDetails.invoiceDetails.amount_paid),
      );
      balance = formatMoney(balance.replace('₦', '	').replace(/,/g, ''), 0);
      formikSetFieldValue('balance', String(balance));
    }
  }, [invoiceDetails]);
  return (
    <>
      <Modal
        showModal={showModal || showRedirectModal}
        renderHeader={
          showRedirectModal
            ? () => (
                <h1 className="mb-2 text-2xl text-center uppercase font-black">
                  Create Policy Proposal
                </h1>
              )
            : renderModalHeader(invoiceModalAction)
        }
        renderFooter={
          showRedirectModal
            ? () => (
                <div className="flex justify-end mt-8">
                  <Button
                    type="button"
                    onClick={() => {
                      if (invoiceModalAction === 'add payment') {
                        setShowRedirectModal(false);
                      } else {
                        closeModal();
                      }
                    }}
                    name="Cancel"
                    className="p-1 mr-1 text-white bg-red-500 border border-white hover:bg-red-400 focus:border-solid focus:border-gray-300"
                  />
                  <Button
                    type="submit"
                    onClick={() => {
                      const { quote_id } = invoiceDetails.invoiceDetails;
                      history.push(`/quotes/${quote_id}/createPolicy`);
                    }}
                    name="Yes"
                    className={
                      'bg-blue-850 hover:bg-blue-730 text-white font-bold'
                    }
                  />
                </div>
              )
            : null
        }
        renderContent={
          showRedirectModal
            ? () => (
                <div>
                  <p>You wish to create policy proposal?</p>
                </div>
              )
            : renderModalContent(
                isInvoiceLoading,
                invoiceModalAction,
                updatedData ? updatedData.status : status,
                updatedData ? formatMoney(updatedData.amount) : amountInsured,
                updatedData ? updatedData.narration : narration,
                updatedData ? formatDate(updatedData.start_date) : startDate,
                updatedData ? formatDate(updatedData.end_date) : endDate,
                closeModal,
                updateInvoiceDetail,
                invoiceId,
                sendInvoiceToClient,
                invoiceDetails,
                addPaymentToAnInvoiceAction,
                updatedData ? updatedData.commission_type : commissionType,
                updatedData ? updatedData.commission_rate : commissionRate,
              )
        }
      />
    </>
  );
};

export default InvoiceDetailModal;
