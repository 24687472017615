import { toast } from 'react-toastify';

import axios from '../../../utils/http';
import {
  REQUEST_ACTION,
  SEND_REQUEST_ACTION,
  SEND_CREDIT_NOTE_SUCCESS,
  SEND_CREDIT_NOTE_FAILURE,
  GET_EXPIRING_POLICY_SUCCESS,
  GET_EXPIRING_POLICY_FAILURE,
  SEND_EXPIRING_POLICY_NOTIFICATION_SUCCESS,
  GET_SINGLE_POLICY_SUCCESS,
  GET_SINGLE_POLICY_FAILURE,
  GET_POLICY_SUCCESS,
  GET_POLICY_FAILURE,
  UPDATE_POLICY_STATUS_SUCCESS,
  UPDATE_POLICY_STATUS_FAILURE,
  UPLOAD_POLICY_DOCUMENT_SUCCESS,
  UPLOAD_POLICY_DOCUMENT_FAILURE,
  SEND_POLICY_DOCUMENT_SUCCESS,
  SEND_POLICY_DOCUMENT_FAILURE,
  CREATE_POLICY_PROPOSAL_SUCCESS,
  CREATE_POLICY_PROPOSAL_FAILURE,
  SEND_PROPOSAL_DOCUMENT_SUCCESS,
  SEND_PROPOSAL_DOCUMENT_FAILURE,
  GET_POLICY_PROPOSAL_DETAILS_SUCCESS,
  GET_POLICY_PROPOSAL_DETAILS_FAILURE,
  CREATE_POLICY_ENDORSEMENT_SUCCESS,
  CREATE_POLICY_ENDORSEMENT_FAILURE,
  GET_POLICY_ENDORSEMENT_SUCCESS,
  GET_SINGLE_POLICY_ENDORSEMENT,
  UPDATE_POLICY_ENDORSEMENT_STATUS,
  UPLOAD_ENDORSEMENT_DOCUMENT_SUCCESS,
  UPLOAD_ENDORSEMENT_DOCUMENT_FAILURE,
  SEND_ENDORSEMENT_DOCUMENT_SUCCESS,
  SEND_ENDORSEMENT_DOCUMENT_FAILURE,
  SEND_PLACEMENT_SLIP_SUCCESS,
  SEND_PLACEMENT_SLIP_FAILURE,
} from './actionTypes';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: REQUEST_ACTION,
});

export const sendRequestAction = () => ({
  type: SEND_REQUEST_ACTION,
});

export const getExpiringPolicy = (payload) => ({
  type: GET_EXPIRING_POLICY_SUCCESS,
  payload,
});

export const getExpiringPolicyFailure = (error) => ({
  type: GET_EXPIRING_POLICY_FAILURE,
  payload: error,
});

export const sendExpiringNotification = (payload) => ({
  type: SEND_EXPIRING_POLICY_NOTIFICATION_SUCCESS,
  payload,
});

export const getSinglePolicy = (payload) => ({
  type: GET_SINGLE_POLICY_SUCCESS,
  payload,
});

export const getSinglePolicyFailure = (error) => ({
  type: GET_SINGLE_POLICY_FAILURE,
  payload: error,
});

export const sendCreditNote = (payload) => ({
  type: SEND_CREDIT_NOTE_SUCCESS,
  payload,
});

export const sendCreditNoteFailure = (payload) => ({
  type: SEND_CREDIT_NOTE_FAILURE,
  payload,
});

export const getPolicies = (payload) => ({
  type: GET_POLICY_SUCCESS,
  payload,
});

export const getRefundedPolicies = (payload) => ({
  type: GET_POLICY_SUCCESS,
  payload,
});

export const getPoliciesFailure = (payload) => ({
  type: GET_POLICY_FAILURE,
  payload,
});

export const updatePolicyStatus = (payload) => ({
  type: UPDATE_POLICY_STATUS_SUCCESS,
  payload,
});

export const updatePolicyStatusFailure = (payload) => ({
  type: UPDATE_POLICY_STATUS_FAILURE,
  payload,
});

export const uploadPolicyDocument = (payload) => ({
  type: UPLOAD_POLICY_DOCUMENT_SUCCESS,
  payload,
  uploadPolicyDocumentSuccess: true,
});

export const uploadPolicyDocumentFailure = (payload) => ({
  type: UPLOAD_POLICY_DOCUMENT_FAILURE,
  payload,
  uploadPolicyDocumentSuccess: false,
});

export const sendPolicyDocument = (payload) => ({
  type: SEND_POLICY_DOCUMENT_SUCCESS,
  payload,
});

export const sendPolicyDocumentFailure = (payload) => ({
  type: SEND_POLICY_DOCUMENT_FAILURE,
  payload,
});

export const createPolicyProposal = (payload) => ({
  type: CREATE_POLICY_PROPOSAL_SUCCESS,
  payload,
});

export const createPolicyProposalFailure = (error) => ({
  type: CREATE_POLICY_PROPOSAL_FAILURE,
  payload: error,
});

export const sendProposalDocument = (payload) => ({
  type: SEND_PROPOSAL_DOCUMENT_SUCCESS,
  payload,
});

export const sendProposalDocumentFailure = (payload) => ({
  type: SEND_PROPOSAL_DOCUMENT_FAILURE,
  payload,
});

export const sendPlacementSlip = (payload) => ({
  type: SEND_PLACEMENT_SLIP_SUCCESS,
  payload,
});

export const sendPlacementSlipFailure = (payload) => ({
  type: SEND_PLACEMENT_SLIP_FAILURE,
  payload,
});

export const getPolicyProposalSuccess = (payload) => ({
  type: GET_POLICY_PROPOSAL_DETAILS_SUCCESS,
  payload,
});

export const getPolicyProposalFailure = (error) => ({
  type: GET_POLICY_PROPOSAL_DETAILS_FAILURE,
  payload: error,
});

export const policyEndorsementSuccess = (payload) => ({
  type: CREATE_POLICY_ENDORSEMENT_SUCCESS,
  payload,
});

export const policyEndorsementFailure = (error) => ({
  type: CREATE_POLICY_ENDORSEMENT_FAILURE,
  payload: error,
});

export const getPolicyEndorsementSuccess = (payload) => ({
  type: GET_POLICY_ENDORSEMENT_SUCCESS,
  payload,
});

export const getSinglePolicyEndorsement = (payload) => ({
  type: GET_SINGLE_POLICY_ENDORSEMENT,
  payload,
});

export const updatePolicyEndorsement = (payload) => ({
  type: UPDATE_POLICY_ENDORSEMENT_STATUS,
  payload,
});

export const uploadEndorsementDocument = (payload) => ({
  type: UPLOAD_ENDORSEMENT_DOCUMENT_SUCCESS,
  payload,
});

export const uploadEndorsementDocumentFailure = (payload) => ({
  type: UPLOAD_ENDORSEMENT_DOCUMENT_FAILURE,
  payload,
});

export const sendEndorsementDocument = (payload) => ({
  type: SEND_ENDORSEMENT_DOCUMENT_SUCCESS,
  payload,
});

export const sendEndorsementDocumentFailure = (payload) => ({
  type: SEND_ENDORSEMENT_DOCUMENT_FAILURE,
  payload,
});

/**
 * Create a thunk which makes an API call to get expiring policies
 *
 *
 * @returns {Function}
 */
export const getExpiringPolicyAction = (payload) => async (dispatch) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.get(
      `/policies/expiringPolicy?daysToExpire=${payload}`,
    );

    dispatch(getExpiringPolicy(data.data));

    return data;
  } catch (error) {
    dispatch(getExpiringPolicyFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to send notification for expiring policy
 *
 * @param {integer} policyId
 *
 * @returns {Function}
 */
export const sendExpiringNotificationAction = (policyId) => async (
  dispatch,
) => {
  dispatch(sendRequestAction());
  try {
    const { data } = await axios.get(
      `/policies/expiringPolicy/${policyId}/notify`,
    );

    dispatch(sendExpiringNotification(data));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get single policy
 * @param {integer} policyId
 * @returns {Function}
 */
export const getSinglePolicyAction = (policyId) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get(`/policies/${policyId}`);
    dispatch(getSinglePolicy(data.data));

    return { success: true, data };
  } catch (error) {
    dispatch(getSinglePolicyFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to send credit note to underwriters
 * @param {integer} invoiceId
 * @returns {Function}
 */
export const sendCreditNoteAction = (invoiceId) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const data = await axios.get(`/policies/${invoiceId}/creditNote`);

    dispatch(sendCreditNote(data.data));
    return { success: true, data };
  } catch (error) {
    dispatch(sendCreditNoteFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get all policies
 * @param {integer} limit
 * @param {integer} page
 * @param {string} status
 * @returns {Function}
 */
export const getPolicyAction = (limit, page, status) => async (dispatch) => {
  dispatch(requestAction());
  limit = limit ? `limit=${limit}` : '';
  page = page ? `&page=${page}` : '';
  status = status ? `&status=${status}` : '';

  try {
    const { data } = await axios.get(`/policies?${limit}${page}${status}`);
    dispatch(getPolicies(data.data));

    return data;
  } catch (error) {
    dispatch(getPoliciesFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get all policies
 * @param {integer} limit
 * @param {integer} page
 * @param {string} status
 * @returns {Function}
 */
export const getRefundedPolicyAction = (limit, page, status) => async (
  dispatch,
) => {
  dispatch(requestAction());
  limit = limit ? `limit=${limit}` : '';
  page = page ? `&page=${page}` : '';
  status = status ? `&status=${status}` : '';

  try {
    const { data } = await axios.get(
      `/policies/allRefund?${limit}${page}${status}`,
    );
    dispatch(getRefundedPolicies(data.data));

    return data;
  } catch (error) {
    dispatch(getPoliciesFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to update policy status
 * @param {integer} policyId
 * @param {object} payload
 * @returns {Function}
 */
export const updatePolicyStatusAction = (policyId, payload) => async (
  dispatch,
) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.patch(`/policies/${policyId}`, payload);
    dispatch(updatePolicyStatus(data));

    return data;
  } catch (error) {
    dispatch(updatePolicyStatusFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to upload policy documents
 * @param {integer} policyId
 * @param {object} payload
 * @returns {Function}
 */
export const uploadPolicyDocumentAction = (policyId, payload) => async (
  dispatch,
) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.post(`/policies/${policyId}/uploads`, payload);
    dispatch(uploadPolicyDocument(data.data));

    return { success: true, data };
  } catch (error) {
    dispatch(uploadPolicyDocumentFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to send policy documents to client
 * @param {integer} policyId
 * @returns {Function}
 */
export const sendPolicyDocumentAction = (policyId) => async (dispatch) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.get(`/policies/${policyId}/policyDocument`);
    dispatch(sendPolicyDocument(data));

    return data;
  } catch (error) {
    dispatch(sendPolicyDocumentFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const createPolicyProposalAction = (policyPayload) => async (
  dispatch,
) => {
  dispatch(requestAction());
  try {
    const data = await axios.post('/policies', policyPayload);
    dispatch(createPolicyProposal(data.data));

    return { data, success: true };
  } catch (error) {
    dispatch(createPolicyProposalFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to send policy proposal documents to underwriter
 * @param {integer} policyId
 * @returns {Function}
 */
export const sendPlacementSlipAction = (policyId, confirmed) => async (
  dispatch,
) => {
  dispatch(requestAction());
  const query = confirmed ? `?confirmed=${confirmed}` : '';

  try {
    const { data } = await axios.get(
      `/policy/${policyId}/sendPlacementSlip${query}`,
    );
    dispatch(sendPlacementSlip(data));

    return { success: true, data };
  } catch (error) {
    dispatch(sendPlacementSlipFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get all policies for a client
 * @param {integer} limit
 * @param {integer} page
 * @param {string} status
 * @returns {Function}
 */
export const getClientPolicyAction = (email, clientType) => async (
  dispatch,
) => {
  try {
    const { data } = await axios.get(
      `/policies?email=${encodeURIComponent(email)}&clientType=${clientType}`,
    );
    dispatch(getPolicies(data.data));

    return data;
  } catch (error) {
    dispatch(getPoliciesFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to search for policy
 * @param {object} payload
 * @returns {Function}
 */
export const searchPolicyAction = (payload) => async () => {
  try {
    const { data } = await axios.post(`/policies/search`, payload);
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get single policy
 * @param {integer} policyId
 * @returns {Function}
 */
export const getPolicyProposalDetailAction = (policyId) => async (dispatch) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.get(`/policies/policyProposal/${policyId}`);
    dispatch(getPolicyProposalSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getPolicyProposalFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to create policy endorsement
 * @param {integer} policyId
 * @returns {Function}
 */
export const policyEndorsementAction = (payload) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.post(`/policies/endorsement`, payload);
    dispatch(policyEndorsementSuccess(data));

    return data;
  } catch (error) {
    dispatch(policyEndorsementFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get policy endorsement
 * @param {integer} limit
 * @param {integer} page
 * @param {string} status
 * @returns {Function}
 */
export const getPolicyEndorsementAction = (limit, page, status) => async (
  dispatch,
) => {
  dispatch(requestAction());

  try {
    limit = limit ? `limit=${limit}` : '';
    page = page ? `&page=${page}` : '';
    status = status ? `&status=${status}` : '';
    const { data } = await axios.get(
      `/policies/endorsements/?${limit}${page}${status}`,
    );
    dispatch(getPolicyEndorsementSuccess(data.data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const refreshComponentAction = () => (dispatch) =>
  dispatch({ type: 'TOGGLE' });

/**
 * Create a thunk which makes an API call to get single policy endorsement
 * @param {integer} endorsementId
 * @returns {Function}
 */
export const getSinglePolicyEndorsementAction = (endorsementId) => async (
  dispatch,
) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.get(`/policies/endorsement/${endorsementId}`);
    dispatch(getSinglePolicyEndorsement(data.data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to update policy endorsement
 * @param {integer} endorsementId
 * @returns {Function}
 */
export const updatePolicyEndorsementAction = (endorsementId, payload) => async (
  dispatch,
) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.patch(
      `/policies/endorsement/${endorsementId}`,
      payload,
    );
    dispatch(updatePolicyEndorsement(data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to upload policy endorsement document
 * @param {integer} endorsementId
 * @param {object} payload
 * @returns {Function}
 */
export const uploadEndorsementDocumentAction = (
  endorsementId,
  payload,
) => async (dispatch) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.post(
      `/policies/endorsement/${endorsementId}/uploads`,
      payload,
    );

    dispatch(uploadEndorsementDocument(data));
    return data;
  } catch (error) {
    dispatch(uploadEndorsementDocumentFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to send policy endorsement documents to client
 * @param {integer} endorsementId
 * @returns {Function}
 */
export const sendEndorsementDocumentAction = (endorsementId) => async (
  dispatch,
) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.get(
      `/policies/endorsement/${endorsementId}/endorsementDocument`,
    );

    dispatch(sendEndorsementDocument(data));
    return data;
  } catch (error) {
    dispatch(sendEndorsementDocumentFailure(error?.response?.data));
    return errorHandler(errorHandler);
  }
};

export const initialState = {
  data: [],
  policyData: [],
  notificationResponse: {},
  singlePolicy: null,
  isLoading: false,
  state: false,
  isSendLoading: false,
  uploadPolicyDocumentSuccess: false,
  creditNote: {},
  policyProposal: null,
  policyProposalError: null,
  policyProposalDetails: {},
  policyEndorsement: [],
  endorsementData: {},
  error: {},
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case SEND_REQUEST_ACTION:
      return {
        ...state,
        isSendLoading: true,
      };
    case 'TOGGLE':
      return {
        ...state,
        state: !state.state,
      };
    case GET_EXPIRING_POLICY_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
      };
    case GET_EXPIRING_POLICY_FAILURE:
      return {
        ...state,
        data: payload,
        isLoading: false,
      };
    case SEND_EXPIRING_POLICY_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationResponse: payload,
        isSendLoading: false,
      };
    case GET_SINGLE_POLICY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        singlePolicy: payload,
      };
    case GET_SINGLE_POLICY_FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case SEND_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        creditNote: payload,
        isLoading: false,
      };
    case SEND_CREDIT_NOTE_FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case CREATE_POLICY_PROPOSAL_SUCCESS:
      return {
        ...state,
        policyProposal: payload,
        isLoading: false,
      };
    case CREATE_POLICY_PROPOSAL_FAILURE:
      return {
        ...state,
        policyProposalError: payload,
        isLoading: false,
      };
    case GET_POLICY_SUCCESS:
      return {
        ...state,
        policyData: payload,
        isLoading: false,
      };
    case GET_POLICY_FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case UPDATE_POLICY_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_POLICY_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case UPLOAD_POLICY_DOCUMENT_SUCCESS:
      return {
        ...state,
        singlePolicy: payload,
        isLoading: false,
      };
    case UPLOAD_POLICY_DOCUMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SEND_POLICY_DOCUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case SEND_POLICY_DOCUMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SEND_PROPOSAL_DOCUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case SEND_PROPOSAL_DOCUMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SEND_PLACEMENT_SLIP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case SEND_PLACEMENT_SLIP_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_POLICY_PROPOSAL_DETAILS_SUCCESS:
      return {
        ...state,
        policyProposalDetails: payload,
        isLoading: false,
      };
    case GET_POLICY_PROPOSAL_DETAILS_FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case CREATE_POLICY_ENDORSEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case CREATE_POLICY_ENDORSEMENT_FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case GET_POLICY_ENDORSEMENT_SUCCESS:
      return {
        ...state,
        policyEndorsement: payload,
        isLoading: false,
      };
    case GET_SINGLE_POLICY_ENDORSEMENT:
      return {
        ...state,
        endorsementData: payload,
        isLoading: false,
      };
    case UPDATE_POLICY_ENDORSEMENT_STATUS:
      return {
        ...state,
        isLoading: false,
      };
    case UPLOAD_ENDORSEMENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case UPLOAD_ENDORSEMENT_DOCUMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case SEND_ENDORSEMENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case SEND_ENDORSEMENT_DOCUMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
