import React, { lazy, Suspense } from 'react';
import { useHistory, Switch } from 'react-router-dom';
import Logout from '../components/Logout';
import SideBar from '../components/SideBar';
import TopNavBar from '../components/TopNavBar';
import InvoiceDetail from '../components/SingleInvoice';
import AuthenticatedRoute from '../components/AuthenticatedRoute';
import LazyScreenLoader from '../components/LazyScreenLoader';
import SubscriptionModal from '../components/SubscriptionModal';

const SignatureIntegration = lazy(() =>
  import('../pages/SignatureIntegration'),
);
const AccountingPeriod = lazy(() => import('../pages/AccountingPeriod'));
const PolicyProposal = lazy(() => import('../pages/PolicyProposal'));
const DashboardPage = lazy(() => import('../pages/Dashboard'));
const ClientSetupPage = lazy(() => import('../pages/ClientSetUp'));
const ClientUpdate = lazy(() => import('../pages/UpdateClient'));
const Users = lazy(() => import('../pages/Users'));
const User = lazy(() => import('../pages/User'));
const Policy = lazy(() => import('../pages/Policy/PolicyLists'));
const RefundedPolicy = lazy(() =>
  import('../pages/Policy/RefundedPremiumLists'),
);
const Quotes = lazy(() => import('../pages/Quotes/CreateQuote'));
const Reports = lazy(() => import('../pages/Reports'));
const Profile = lazy(() => import('../pages/Profile'));
const SingleQuoteDetails = lazy(() => import('../pages/SingleQuoteDetails'));
const SingleIndividualClientDetails = lazy(() =>
  import('../pages/SingleIndividualClientDetails'),
);
const ExchangeRateHistory = lazy(() => import('../pages/ExchangeHistory'));
const SingleCorporateClientDetails = lazy(() =>
  import('../pages/SingleCorporateClientDetails'),
);
const Roles = lazy(() => import('../pages/Roles'));
const Role = lazy(() => import('../pages/Role'));
const Groups = lazy(() => import('../pages/Groups'));
const Group = lazy(() => import('../pages/Group'));
const Permissions = lazy(() => import('../pages/Permissions'));
const DebitNote = lazy(() => import('../pages/DebitNote'));
const CorporateDirector = lazy(() => import('../pages/CorporateDirector'));
const AddProductForm = lazy(() => import('../pages/Products/createProduct'));
const NewInvoicePage = lazy(() => import('../pages/NewInvoice'));
const Invoices = lazy(() => import('../pages/Invoices'));
const MultiCurrency = lazy(() => import('../pages/MultiCurrency'));
const Transactions = lazy(() => import('../pages/Transactions'));
const ChartOfAccount = lazy(() => import('../pages/ChartOfAccount'));
const ChartOfAccountDetailPage = lazy(() =>
  import('../pages/ChartOfAccountDetailPage'),
);
const TransactionRecords = lazy(() =>
  import('../pages/Transactions/TransactionRecords'),
);
const RemittanceManagement = lazy(() =>
  import('../pages/RemittanceManagement/PremiumRemittance'),
);
const RecordDetails = lazy(() => import('../pages/Transactions/RecordDetails'));
const SummaryOfAccountPage = lazy(() => import('../pages/SummaryOfAccounts'));
const InvoicePaymentsPage = lazy(() => import('../pages/InvoicePayments'));
const TrialBalancePage = lazy(() => import('../pages/TrialBalance'));
const BalanceSheet = lazy(() => import('../pages/Transactions/BalanceSheet'));
const AccountTransaction = lazy(() => import('../pages/AccountTransaction'));
const CreditNote = lazy(() => import('../pages/CreditNote'));
const CashFlowPage = lazy(() => import('../pages/CashFlow'));
const DebitInvoice = lazy(() => import('../pages/DebitInvoice'));
const IncomeStatementPage = lazy(() => import('../pages/IncomeStatementPage'));
const RegulatoryReport = lazy(() => import('../components/RegulatoryReports'));
const PoliticalEngaged = lazy(() =>
  import('../components/ReportTable/PoliticalEngaged'),
);
const ScheduledUnremitted = lazy(() =>
  import('../components/ReportTable/ScheduleOfUnremittedReports'),
);
const PremiumIncomeProduction = lazy(() =>
  import('../components/ReportTable/PremiumIncomeProduction'),
);
const ReturnedAndRefundedPremium = lazy(() =>
  import('../components/ReportTable/ReturnedAndRefundedPremium'),
);
const CommissionRebateAndReturnedPremium = lazy(() =>
  import('../components/ReportTable/CommissionRebateAndRefundPremium'),
);
const BusinessGenerated = lazy(() =>
  import('../components/ReportTable/BusinessGenerated'),
);
const ScheduleOfUnremittances = lazy(() =>
  import('../components/ReportTable/BiannualScheduleOfUnremittances'),
);
const BiAnnualClientAccount = lazy(() =>
  import('../components/ReportTable/BiAnnualClientsAccount'),
);
const CommissionIncome = lazy(() =>
  import('../components/ReportTable/CommissionIncome'),
);
const SinglePolicy = lazy(() => import('../pages/Policy/SinglePolicyDetail'));
const CreatePolicy = lazy(() => import('../pages/Policy/CreatePolicy'));
const PolicyEndorsement = lazy(() =>
  import('../pages/Policy/PolicyEndorsement'),
);
const RegisterPolicyEndorsement = lazy(() =>
  import('../pages/Policy/RegisterPolicyEndorsement'),
);
const UnderwriterPageList = lazy(() => import('../pages/UnderwriterList'));
const UnderwriterDetailsPage = lazy(() =>
  import('../pages/UnderwriterDetails'),
);
const PolicyEndorsementDetail = lazy(() =>
  import('../pages/Policy/PolicyEndorsementDetail'),
);
const QuoteList = lazy(() => import('../pages/Quotes/QuoteList'));
const Settings = lazy(() => import('../pages/Settings'));
const Claim = lazy(() => import('../pages/Claim'));
const AllClients = lazy(() => import('../pages/AllClients'));
const ProductList = lazy(() => import('../pages/Products/productLists'));
const ProductDetails = lazy(() => import('../pages/Products/productDetails'));
const RequestQuote = lazy(() => import('../pages/Quotes/RequestQuote'));
const EmailIntegration = lazy(() => import('../pages/EmailIntegration'));
// const PaymentForm = lazy (() => import ('../components/PaymentForm'));
const SubscriptionPlans = lazy(() => import('../pages/SubscriptionPlans'));
const License = lazy(() => import('../pages/License'));
const RemittedPremium = lazy(() =>
  import('../pages/RemittanceManagement/RemittedPremium'),
);

const AuthenticatedRoutes = (props) => {
  const history = useHistory();

  return (
    <Logout>
      <SubscriptionModal>
        <div className="lg:h-screen md:flex bg-white-100">
          <div className="md:w-1/4 lg:w-1/5">
            <SideBar logOut={() => props.signOut(history)} />
          </div>
          <div className="w-full md:h-screen md:w-3/4 lg:w-4/5">
            <TopNavBar />
            <Suspense fallback={<LazyScreenLoader />}>
              <Switch>
                <AuthenticatedRoute
                  path="/dashboard"
                  component={DashboardPage}
                />

                <AuthenticatedRoute
                  path="/client-setup"
                  component={ClientSetupPage}
                />
                <AuthenticatedRoute
                  exact
                  path="/all-clients"
                  component={AllClients}
                />
                <AuthenticatedRoute exact path="/profile" component={Profile} />
                <AuthenticatedRoute exact path="/quotes" component={Quotes} />
                <AuthenticatedRoute
                  exact
                  path="/clients/:clientId/individual"
                  component={SingleIndividualClientDetails}
                />
                <AuthenticatedRoute
                  exact
                  path="/clients/:clientId/corporate"
                  component={SingleCorporateClientDetails}
                />
                <AuthenticatedRoute
                  exact
                  path="/account/exchangeRate/:exchangeRateId/history"
                  component={ExchangeRateHistory}
                />
                <AuthenticatedRoute
                  exact
                  path="/clients/individual/:clientId"
                  component={ClientUpdate}
                />
                <AuthenticatedRoute
                  exact
                  path="/clients/corporate/:clientId"
                  component={ClientUpdate}
                />
                <AuthenticatedRoute exact path="/policies" component={Policy} />
                <AuthenticatedRoute
                  exact
                  path="/policies/allRefund"
                  component={RefundedPolicy}
                />
                <AuthenticatedRoute exact path="/users" component={Users} />
                <AuthenticatedRoute
                  exact
                  path="/users/:userId"
                  component={User}
                />
                <AuthenticatedRoute exact path="/roles" component={Roles} />
                <AuthenticatedRoute
                  exact
                  path="/roles/:roleId"
                  component={Role}
                />
                <AuthenticatedRoute exact path="/groups" component={Groups} />
                <AuthenticatedRoute
                  exact
                  path="/groups/:groupId"
                  component={Group}
                />
                <AuthenticatedRoute
                  exact
                  path="/permissions"
                  component={Permissions}
                />
                <AuthenticatedRoute
                  exact
                  path="/quotes/:quoteId"
                  component={SingleQuoteDetails}
                />
                <AuthenticatedRoute
                  exact
                  path="/debitNote/:invoiceId"
                  component={DebitNote}
                />
                <AuthenticatedRoute
                  exact
                  path="/creditNote/:invoiceId"
                  component={CreditNote}
                />
                <AuthenticatedRoute
                  exact
                  path="/quotes/:quoteId/createPolicy"
                  component={PolicyProposal}
                />
                <AuthenticatedRoute
                  exact
                  path="/director/:corporateClientId"
                  component={CorporateDirector}
                />
                <AuthenticatedRoute
                  exact
                  path="/products"
                  component={ProductList}
                />
                <AuthenticatedRoute
                  exact
                  path="/product/:productId"
                  component={ProductDetails}
                />
                <AuthenticatedRoute
                  exact
                  path="/product"
                  component={AddProductForm}
                />
                <AuthenticatedRoute
                  exact
                  path="/settings"
                  component={Settings}
                />
                <AuthenticatedRoute
                  exact
                  path="/account/general-ledger"
                  component={ChartOfAccount}
                />
                <AuthenticatedRoute
                  exact
                  path="/account/invoices"
                  component={Invoices}
                />
                <AuthenticatedRoute
                  exact
                  path="/account/multi-currency"
                  component={MultiCurrency}
                />
                <AuthenticatedRoute
                  exact
                  path="/account/invoice/:id"
                  component={InvoiceDetail}
                />
                <AuthenticatedRoute
                  exact
                  path="/account/invoices/:invoiceId/createPolicy"
                  component={PolicyProposal}
                />
                <AuthenticatedRoute
                  exact
                  path="/account/accounting-periods"
                  component={AccountingPeriod}
                />
                <AuthenticatedRoute
                  exact
                  path="/reports/balance-sheet"
                  component={BalanceSheet}
                />

                <AuthenticatedRoute
                  exact
                  path="/account/transactions/add"
                  component={Transactions}
                />

                <AuthenticatedRoute
                  exact
                  path="/account/transactions"
                  component={TransactionRecords}
                />

                <AuthenticatedRoute
                  exact
                  path="/remit-premium"
                  component={RemittanceManagement}
                />

                <AuthenticatedRoute
                  exact
                  path="/premium-remitted"
                  component={RemittedPremium}
                />

                <AuthenticatedRoute
                  exact
                  path="/account/transactions/:refNo/details"
                  component={RecordDetails}
                />
                <AuthenticatedRoute
                  exact
                  path="/account/new/invoice"
                  component={NewInvoicePage}
                />
                <AuthenticatedRoute
                  exact
                  path="/account/general-ledger/:accountId"
                  component={ChartOfAccountDetailPage}
                />
                <AuthenticatedRoute
                  exact
                  path="/reports/summary"
                  component={SummaryOfAccountPage}
                />
                <AuthenticatedRoute
                  exact
                  path="/invoice/:invoiceNo/payments"
                  component={InvoicePaymentsPage}
                />
                <AuthenticatedRoute
                  exact
                  path="/reports/trialBalance"
                  component={TrialBalancePage}
                />
                <AuthenticatedRoute
                  exact
                  path="/account/transaction/:accountId"
                  component={AccountTransaction}
                />

                <AuthenticatedRoute
                  exact
                  path="/accountmanagement/license"
                  component={License}
                />

                <AuthenticatedRoute
                  exact
                  path="/accountmanagement/view-plans"
                  component={SubscriptionPlans}
                />

                <AuthenticatedRoute
                  exact
                  path="/reports/cash-flow"
                  component={CashFlowPage}
                />
                <AuthenticatedRoute
                  exact
                  path="/debitInvoice/:invoiceId"
                  component={DebitInvoice}
                />
                <AuthenticatedRoute
                  exact
                  path="/reports/incomeStatement"
                  component={IncomeStatementPage}
                />
                <AuthenticatedRoute
                  exact
                  path="/reports/regulatory-report"
                  component={RegulatoryReport}
                />
                <AuthenticatedRoute
                  exact
                  path="/reports/regulatory-report/politically-engaged"
                  component={PoliticalEngaged}
                />
                <AuthenticatedRoute
                  exact
                  path="/reports/regulatory-report/scheduled-unremitted"
                  component={ScheduledUnremitted}
                />
                <AuthenticatedRoute
                  exact
                  path="/reports/regulatory-report/premium-income-production"
                  component={PremiumIncomeProduction}
                />
                <AuthenticatedRoute
                  exact
                  path="/reports/regulatory-report/return-and-refund-premium"
                  component={ReturnedAndRefundedPremium}
                />
                <AuthenticatedRoute
                  exact
                  path="/reports/regulatory-report/crr-premium"
                  component={CommissionRebateAndReturnedPremium}
                />
                <AuthenticatedRoute
                  exact
                  path="/reports/regulatory-report/business-generated"
                  component={BusinessGenerated}
                />
                <AuthenticatedRoute
                  exact
                  path="/reports/regulatory-report/schedule-of-unremittance"
                  component={ScheduleOfUnremittances}
                />
                <AuthenticatedRoute
                  exact
                  path="/reports/regulatory-report/biannual-client-account"
                  component={BiAnnualClientAccount}
                />
                <AuthenticatedRoute
                  exact
                  path="/reports/regulatory-report/commission-income"
                  component={CommissionIncome}
                />
                <AuthenticatedRoute
                  exact
                  path="/policies/:id"
                  component={SinglePolicy}
                />
                <AuthenticatedRoute
                  exact
                  path="/policy/new"
                  component={CreatePolicy}
                />
                <AuthenticatedRoute
                  exact
                  path="/policy/endorsement"
                  component={PolicyEndorsement}
                />
                <AuthenticatedRoute
                  exact
                  path="/policy/endorsement/register"
                  component={RegisterPolicyEndorsement}
                />
                <AuthenticatedRoute
                  exact
                  path="/underwriters"
                  component={UnderwriterPageList}
                />
                <AuthenticatedRoute
                  exact
                  path="/underwriter/:underwriterId"
                  component={UnderwriterDetailsPage}
                />
                <AuthenticatedRoute
                  exact
                  path="/policy/endorsement/:endorsementId"
                  component={PolicyEndorsementDetail}
                />
                <AuthenticatedRoute
                  exact
                  path="/list/quotes"
                  component={QuoteList}
                />
                <AuthenticatedRoute path="/claims" component={Claim} />
                <AuthenticatedRoute
                  exact
                  path="/request-quote"
                  component={RequestQuote}
                />
                <AuthenticatedRoute
                  exact
                  path="/email-integration"
                  component={EmailIntegration}
                />
                <AuthenticatedRoute
                  exact
                  path="/signature-integration"
                  component={SignatureIntegration}
                />
                <AuthenticatedRoute path="/reports" component={Reports} />
              </Switch>
            </Suspense>
          </div>
        </div>
      </SubscriptionModal>
    </Logout>
  );
};

export default AuthenticatedRoutes;
